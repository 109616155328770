.quiz-intro {
  .fresh-img {
    position: absolute;
    top: 20px;
    left: 16px;

    @include media-breakpoint-down(lg) {
      height: 52px;
      top: 10px;
      left: 8px;
    }
  }

  .intro-image-wrap {
    display: flex;
    align-items: flex-end;
  }

  .intro-img-wrap {
    height: 465px;

    @include media-breakpoint-down(lg) {
      height: 400px;
      padding-right: 2rem;
    }
  }

  .intro-image {
    height: 100%;
  }

  .mobile-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    margin-top: 1rem;
  }

  .intro-image-mobile {
    height: 100%;
  }

  .intro-wrapper {
    padding: 3rem 4.4rem 0rem 4.4rem;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin: 1rem auto 0rem auto;
      padding: 4rem 0.7rem 0rem 0.7rem;
    }
  }

  .h1-welcome {
    color: $yellow;
    letter-spacing: -0.01em;
    line-height: 2.625rem;
    font-size: 2.5rem;

    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
      line-height: 2rem;
    }

    span {
      font-family: $headings-font-family;
      letter-spacing: 3px;
      display: block;
      font-size: 1.25rem;
      color: $white;

      @include media-breakpoint-down(sm) {
        font-size: 1.125rem;
      }
    }
  }

  .intro-description {
    font-size: 1.125rem;
    line-height: 1.4rem;
    color: $white;
    font-weight: normal;
    width: 460px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      font-size: 0.95rem;
      line-height: 1.2rem;
      width: 100%;
    }
  }

  .usps {
    margin-top: 1rem;
    padding: 0 10px;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
    }

    p {
      font-size: 0.875rem;
      line-height: normal;
      @include media-breakpoint-down(sm) {
        font-size: 0.7rem;
        margin-bottom: 6px;
      }
    }

    .icon {
      margin-bottom: 10px;
    }

    img {
      height: 50px;
      @include media-breakpoint-down(sm) {
        height: 36px;
      }
    }
  }

  .prizes {
    font-size: 1rem;
    font-weight: normal;
    flex-direction: column;

    a,
    span {
      color: $yellow;
    }

    span {
      font-size: 1rem;
      text-decoration: underline;
      cursor: pointer;

      @include media-breakpoint-down(sm) {
        font-size: 0.87rem;
      }
    }

    .cond {
      font-size: 0.75rem;
      text-decoration: none;
    }
  }

  .introscreen-btn {
    font-size: 1.25rem;
    padding: 0.4rem 2.7rem;
    margin-bottom: 1rem;
    margin-top: 1.2rem;

    @include media-breakpoint-down(md) {
      font-size: 0.85rem;
      margin-bottom: 0.7rem;
    }

    // @media (max-height: 620px) {
    //   font-size: 0.85rem;
    //   margin-bottom: 0.7rem;
    //   margin-top: 5px;
    // }
  }
}
