.quiz-optin {
  .fresh-opt {
    position: absolute;
    top: 25px;
    left: 25px;
    height: 57px;

    @include media-breakpoint-down(lg) {
      height: 30px;
      top: 12px;
      left: 12px;
    }
  }

  .optin-content {
    padding-top: 5rem;
    max-width: 600px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
      padding-top: 5.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .sound-optin {
    position: absolute;
    top: 20px;
    right: 25px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    @include media-breakpoint-down(md) {
      height: 30px;
      bottom: 15px;
      right: 10px;
    }

    :hover,
    :focus {
      outline: none;
    }

    img {
      height: 100%;
    }
  }

  .h1-optin {
    color: $yellow;
    font-size: 2.2rem;
    line-height: 2.7rem;
    padding: 0 2rem;
    @include media-breakpoint-down(sm) {
      font-size: 1.625rem;
      line-height: 2rem;
    }
  }

  .info {
    font-size: 16px;
    line-height: 20px;
    font-family: $font-family-sans-serif;
    padding: 0 3.8rem;

    @include media-breakpoint-down(sm) {
      font-size: 14px;
      line-height: 20px;
      padding: 0 2.3rem;
    }
  }

  .optin-btn-wrapper {
    margin-top: 0.5rem;
    display: inline-flex;
    flex-direction: column;
    max-width: 220px;

    .btn {
      width: 200px;
    }

    .optin-btn {
      margin-top: 10px;
      font-size: 1.2rem;
      padding: 0.35rem auto;
    }

    .guest-btn {
      margin-top: 10px;
      font-size: 12px;
      border: 2.5px solid white;
      transition: opacity 0.3s;

      &:hover,
      &:focus {
        opacity: 0.8;
        background: transparent;
      }

      &:active {
        opacity: 0.6;
        background: transparent;
      }
    }

    span {
      font-size: 10px;
    }

    .prize {
      text-decoration: underline;
      color: $yellow;
      cursor: pointer;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 2.2rem;
    }
  }
}
