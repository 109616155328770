.results {
  padding-bottom: 2rem;

  .result-wrapper {
    margin: 10px 0 200px 0;
  }

  .fresh-result {
    position: absolute;
    top: 25px;
    left: 25px;
    height: 57px;

    @include media-breakpoint-down(lg) {
      height: 30px;
      top: 12px;
      left: 12px;
    }
  }

  .results-intro {
    font-size: 40px;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
      line-height: 35px;
    }
  }

  .heading1 {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 42px;
    letter-spacing: 4px;
    font-family: 'Bebas-Neue';

    @include media-breakpoint-down(lg) {
      font-size: 40px;
      line-height: 42px;
    }

    img {
      height: 25px;
      margin: 0 10px 0 0;
    }
  }

  .heading-2 {
    font-size: 25px;
    line-height: 40px;

    @include media-breakpoint-down(lg) {
      font-size: 22px;
      line-height: 30px;
    }
  }

  .product-description {
    font-size: 16px;
    line-height: 22px;
    padding-top: 10px;

    @include media-breakpoint-down(lg) {
      text-align: justify;
    }
  }

  .product-image {
    text-align: end;

    @include media-breakpoint-down(lg) {
      text-align: center;
    }

    img {
      //height: 350px;
      @include media-breakpoint-down(lg) {
        //height: 330px;
      }
    }
  }

  .heading-4 {
    font-size: 35px;
    line-height: 40px;

    @include media-breakpoint-down(lg) {
      font-size: 30px;
      line-height: 35px;
    }
  }

  .button-results {
    max-width: 250px;
    margin: 1rem auto;

    .btn {
      font-size: 16px;
    }
  }

  .btn-over {
    margin: 1rem auto;
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    max-height: 30px;

    .btn {
      border: 2.5px solid white;
      font-size: 14px;
    }
  }

  .share-wrapper {
    display: flex;
    align-items: center;
    border-radius: 5px;
    justify-content: center;
    height: 38px;
    background-color: $white;
    padding: 3px 10px;

    .btn {
      border: none;
    }

    svg {
      height: 25px;
    }
  }

  .share-button {
    margin: 2px 4px;
    display: inline-flex;
  }

  .share-button:hover svg path {
    fill: $yellow; /* Fill color on hover */
  }

  .share-button:active svg path {
    fill: $yellow; /* Fill color on click */
  }
}
