.progress-container {
  position: absolute;
  top: 35px;
  right: 25px;
  font-size: 24px;
  font-weight: 700;
  padding: 15px auto;
  letter-spacing: 2px;

  @include media-breakpoint-down(lg) {
    font-size: 20px;
    height: 30px;
    top: 10px;
    right: 10px;
  }
}
