.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 8rem auto;

  @include media-breakpoint-down(lg) {
    margin: 10rem auto;
  }

  .progress-wrap {
    width: 220px;
    position: relative;
  }

  .progress2 {
    border-radius: 30px;
    background-color: #fff;
  }

  .progress-bar2 {
    height: 18px;
    border-radius: 30px;
    transition: 0.4s linear;
    transition-property: width, background-color;
  }

  .progress-moved .progress-bar2 {
    background-color: #ffc814;
    animation: progress 5s infinite;
  }

  @keyframes progress {
    0% {
      width: 0%;
      background: #fff5a0;
    }

    100% {
      width: 100%;
      background: #fff000;
      box-shadow: 0 0 40px #ffc814;
    }
  }
}
