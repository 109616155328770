.cards-wrapper {
  margin: 2rem auto;
  justify-content: center;
  .card {
    width: 260px;
    margin: 0 10px;
    border: none;
    border-radius: 0;
    text-align: justify;
    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }
  }
}
