.modal.modal-fullscreen {
  width: 100vw;
  margin: 0;
  padding: 0;
  max-width: none;
  border-radius: 0;
  font-family: $font-family-sans-serif;
  overflow-x: hidden;

  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.2s ease-in-out;
  background-image: url(/assets/images/svg/lidl-bg-result.png);
  min-height: 100vh;

  @include media-breakpoint-down(lg) {
    background-image: url(/assets/images/svg/lidl-bg-result-mobile.png);
    background-size: cover;
  }

  .fresh-prize {
    position: absolute;
    top: 25px;
    left: 25px;
    height: 57px;

    @include media-breakpoint-down(lg) {
      height: 30px;
      top: 12px;
      left: 12px;
    }
  }

  h1 {
    color: $yellow;
    font-size: 45px;
  }

  h2 {
    color: $white;
    font-size: 25px;
  }

  p {
    font-size: 14px;
  }

  .btn-wrap {
    width: 180px;
    margin: 5px auto;
    text-align: center;
  }

  .modal-content {
    display: flex;
    position: relative;
    background: transparent;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    max-width: 850px;
    padding: 10px;
    margin: 3.5rem auto;
  }

  .exit-button {
    background-color: transparent;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 2;

    &:active,
    &:focus {
      border: none;
      transform: scale(1.05);
    }
  }

  .prize-descr {
    font-size: 14px;
    max-width: 355px;
    margin: 2rem auto;
  }

  .prize-wrapper {
    margin: 5px 0 30px 0;

    h2 {
      color: $yellow;
    }
  }

  .main-prize {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    margin: 10px;

    h3 {
      margin: 10px auto;
    }
  }

  .main-media {
    height: 180px;
    margin-bottom: 10px;

    img {
      height: 100%;
    }
  }

  .prize-card {
    background: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    margin: 10px;
    max-width: 260px;

    @include media-breakpoint-down(lg) {
      max-width: 300px;
    }

    h3 {
      margin: 10px auto;
    }
  }

  .prize-media {
    height: 110px;
    margin-bottom: 8px;

    @include media-breakpoint-down(lg) {
      height: 140px;
    }

    img {
      height: 100%;
    }
  }

  .exit-button img {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }
}
