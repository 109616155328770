.products-wrapper {
  padding: 30px 5px 20px 5px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;

  @include media-breakpoint-down(lg) {
    padding: 30px 10px 20px 10px;
  }

  h2 {
    font-size: 35px;
    line-height: 42px;

    @include media-breakpoint-down(lg) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  .btn {
    font-size: 16px;
  }
}

.products-row {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  margin-top: 10px;
  @include media-breakpoint-down(lg) {
    align-items: center;
    justify-content: center;
  }
}

.product {
  height: 190px;
  width: 190px;
  padding: 0;
  @include media-breakpoint-down(lg) {
    height: 200px;
    width: 200px;
    padding: 5px;
  }
}

.product img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
