// Colors

$white: #fff;
$black: #000;
$yellow: #ffed00;
$blue: #005ba2;

$yellow-gradient: linear-gradient(90deg, #fff49a 0%, #fff200 100%);

$primary: $white;
$secondary: $black;
$headings-color: $white;

$font-family-sans-serif: 'Lidl-Pro', sans-serif;
$headings-font-family: 'Lidl-Cond', sans-serif;

$utilities: (
  'custom-margin-top': (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 4rem,
    ),
  ),
  'opacity': (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 9,
);

$theme-colors: (
  'primary': $white,
  'secondary': $black,
  'white': $white,
  'yellow': $yellow,
  'blue': $blue,
);

$body-color: white;

// Typography
$font-size-base: 0.8rem;
$headings-font-weight: 700;
$headings-line-height: 1;
$h1-font-size: 2.5rem; //heading
$h2-font-size: 1.5rem; //question
$h3-font-size: 1.125; //descr

// Button
$btn-focus-box-shadow: 0;
$btn-font-family: $headings-font-family;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Form
$input-bg: $white;

$input-btn-font-size-sm: 0.8rem;
$input-border-color: $white;
$input-border-width: 3px;
$input-focus-border-color: $yellow;
$input-color: $blue;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 0.4rem;

$form-check-input-bg: $input-bg !default;
$form-check-input-width: 1.5em;
$form-check-radio-border-radius: 50%;
$form-check-input-border-radius: 50%;
$form-check-input-border: 2px solid $white;
$form-check-input-focus-border: 2px solid $yellow;
$form-check-input-checked-color: $blue;
$form-check-input-checked-bg-color: $yellow;
$form-check-input-checked-border-color: $yellow;
$form-check-input-focus-box-shadow: 'none';

// Progress
$progress-height: 1.2rem;
$progress-font-size: $font-size-base * 0.85;

// Cards
$card-border-radius: none;

$enable-negative-margins: true;

@import '~bootstrap/scss/bootstrap';
