.optin-form {
  width: 330px;
  margin: 1rem auto;
  font-family: $font-family-sans-serif;

  input {
    font-weight: 600;
  }

  .form-group {
    margin: 1rem 0;
  }

  .form-check {
    text-align: left;
    font-size: 10px;

    @include media-breakpoint-down(sm) {
      margin-top: 10px;
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: rgba($black, 0.2);
  }

  .warning-text {
    font-size: 14px;
    padding-top: 4px;
    font-weight: 600;
  }
}
