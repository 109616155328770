// icons
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css');

// --- Fonts ---
//pro
@font-face {
  font-family: 'Lidl-Pro';
  src: url('../fonts/LidlFontPro/LidlFontPro-Book.woff2') format('woff2'),
    url('../fonts/LidlFontPro/LidlFontPro-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lidl-Pro';
  src: url('../fonts/LidlFontPro/LidlFontPro-Semibold.woff2') format('woff2'),
    url('../fonts/LidlFontPro/LidlFontPro-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lidl-Pro';
  src: url('../fonts/LidlFontPro/LidlFontPro-Bold.woff2') format('woff2'),
    url('../fonts/LidlFontPro/LidlFontPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

//cond
@font-face {
  font-family: 'Lidl-Cond';
  src: url('../fonts/LidlFontCondPro/LidlFontCondPro-Bold.woff2') format('woff2'),
    url('../fonts/LidlFontCondPro/LidlFontCondPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lidl-Cond';
  src: url('../fonts/LidlFontCondPro/LidlFontCondPro-Regular.woff2') format('woff2'),
    url('../fonts/LidlFontCondPro/LidlFontCondPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

//bebas
@font-face {
  font-family: 'Bebas-Neue';
  src: url('../fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'),
    url('../fonts/BebasNeue/BebasNeue-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

//body

body {
  box-sizing: border-box;
  color: $white;
  font-family: 'Lidl', sans-serif;
  margin: 0 auto;
  overflow-x: hidden;
  //overflow: hidden;
  padding: 0;
}

html,
#root {
  //min-height: 100vh;
  background-color: #0050aa;
}

.quiz {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  //min-height: 100vh;
  max-width: 100vw;
  font-family: $font-family-sans-serif;

  .wrapper {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 0.2s ease-in-out;
    background-image: url(/assets/images/svg/lidl-bg.jpg);
    //min-height: 100vh;

    @include media-breakpoint-down(md) {
      background-image: url(/assets/images/svg/lidl-bg-mobile.jpg);
      background-size: cover;
    }
  }

  // Max content width
  .inner-wrapper {
    width: 870px;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .results {
    background-image: url(/assets/images/svg/lidl-bg-result.png);
    transition: background-image 0.2s ease-in-out;
    background-position: center top;
    background-repeat: no-repeat;

    @include media-breakpoint-down(md) {
      background-image: url(/assets/images/svg/lidl-bg-result-mobile.png);
      background-size: cover;
    }
  }
}

// Button
.btn {
  text-transform: uppercase;
  font-weight: 900;
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn-back {
  border: none;
  color: $yellow;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 0.85;
  outline: none;
  position: relative;
  margin-top: 50px;
  text-align: center;
  margin-bottom: 1.5rem;

  &:active,
  &:focus {
    color: $yellow;
    filter: brightness(75%);
  }
  &:hover {
    color: $yellow;
    transform: scale(1.05);
  }

  @include media-breakpoint-down(lg) {
    margin-top: 15px;
    margin-bottom: 1rem;
  }

  .bi-arrow-left {
    font-size: 20px;
    position: relative;
    top: 2px;
  }
}
