.quiz-questions {
  overflow: hidden;

  .fresh-quest {
    position: absolute;
    top: 25px;
    left: 25px;
    height: 57px;

    @include media-breakpoint-down(lg) {
      height: 30px;
      top: 12px;
      left: 12px;
    }
  }

  .explosion-overlay {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
  }

  .sound-icon {
    position: absolute;
    bottom: 20px;
    right: 25px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    @include media-breakpoint-down(md) {
      height: 30px;
      bottom: 15px;
      right: 10px;
    }

    :hover,
    :focus {
      outline: none;
    }

    img {
      height: 100%;
    }
  }

  .question-content {
    padding-top: 3rem;
  }

  .lead {
    font-size: 18px;
    font-weight: normal;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    .question-lead {
      max-width: 600px;
      margin: 2rem auto;

      @include media-breakpoint-down(lg) {
        width: 100%;
        margin: 0.5rem auto;
        font-size: 16px;
        padding: 0 0.8rem;
      }
    }

    .question-text {
      font-size: 24px;
      @include media-breakpoint-down(lg) {
        font-size: 22px;
      }
    }
  }

  .image-question-answer-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    align-items: space-between;
    margin-bottom: 5.5rem;
    //width: 750px;

    @include media-breakpoint-down(lg) {
      max-width: 310px;
      padding: 0;
      margin-bottom: 3.5rem;
    }
  }

  .text-question-answer-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: space-between;
    align-items: space-between;
    max-width: 700px;

    @include media-breakpoint-down(lg) {
      justify-content: center;

      padding: 0;
    }
  }

  // .image-answer-wrapper {
  // }

  .text-answer-wrapper {
    padding: 0;
    //margin: 2px 4px;

    @include media-breakpoint-down(sm) {
      margin: 0;
    }
  }

  .btn-question {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    //padding: 0 10px;
    transition: 1s;
    text-transform: none;
    font-size: 18px;

    &.image-answer {
      border: 0;
      outline: none;
      //padding: 0 5px;

      &:hover {
        > div {
          opacity: 0.7;
        }
      }

      @media (hover: none) {
        &:hover > div {
          opacity: initial;
        }
      }

      &:active,
      &:focus {
        > div {
          filter: brightness(95%);
          transition: 0.5s;
          border: solid 5px $yellow;
        }
      }

      > div {
        border: none;
        //width: 180px;
        //height: 180px;
        overflow: hidden;

        img {
          width: 100%;
        }
        @include media-breakpoint-down(md) {
          // width: 150px;
          //height: 150px;
        }
      }
    }

    &.text-answer {
      background-color: $white;
      margin: 5px auto;
      text-align: center;
      width: 340px;
      height: 60px;
      line-height: 1.2;
      padding: 9px 40px;
      color: $blue;

      &:active,
      &:focus {
        //filter: brightness(75%);
        background-color: $yellow;
      }
      &:hover {
        opacity: 0.7 !important;
      }

      // Disable hover on mobile devices
      @media (hover: none) {
        &:hover {
          opacity: 1 !important;
        }
      }

      @include media-breakpoint-down(lg) {
        width: 310px;
      }

      @include media-breakpoint-down(sm) {
        margin: 6px;
      }
    }
  }

  .quest-info {
    margin: 2rem;

    @include media-breakpoint-down(md) {
      margin: 0.8rem 0rem;
    }
  }
}
