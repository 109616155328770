.bars-container {
  margin: 60px 0 30px -35px;

  @include media-breakpoint-down(lg) {
    margin: 25px 0 20px 5px;
    display: flex;
    justify-content: center;
  }
}

.bars-wrapper {
  margin: 1.6rem auto;

  h3 {
    font-size: 24px;
    line-height: 30px;

    img {
      margin-bottom: 5px;
    }
  }

  .small-cell {
    width: 35px;
    text-align: center;

    img {
      height: 20px;
      width: auto;
    }
  }

  .name-cell {
    width: 350px;
    @include media-breakpoint-down(lg) {
      width: 260px;
      text-align: left;
    }
  }

  .large-cell {
    width: 45px;
    text-align: center;

    img {
      height: 28px;
      width: auto;
    }
  }

  .progress {
    border-radius: 20px;
    height: 22px;
  }

  .bar-tooltip {
    color: white;
    max-width: 270px;
    background-color: $blue;
    padding: 4px 16px;

    @include media-breakpoint-down(md) {
      max-width: 115px;
      padding: 4px 4px;
    }
  }
}
